<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการเอเย่นต์
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mx-auto mb-1">
            <b-col cols="12 col-md-10" class="pr-1 pl-1">
              <div>
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่สมัคร (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่สมัคร (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-2">
              <div class="btn-group">
                <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light"> ค้นหา </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- End Table Function -->
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1">
              <b-row>
                <div class="btn-group btn-group-sm mx-auto" role="group">
                  <b-button variant="outline-secondary" class="btn" disabled>role: </b-button>
                  <b-button class="btn btn-success" @click="sorted='pa';fetch()">ซีเนียร์</b-button>
                  <b-button class="btn btn-danger" @click="sorted='mg';fetch()">มาสเตอร์</b-button>
                  <b-button class="btn btn-warning" @click="sorted='ag';fetch()">เอเย่นต์</b-button>
                  <b-button class="btn btn-info" @click="sorted='sa';fetch()">ซับเอเย่นต์</b-button>
                  <b-button class="btn bg-gradient-primary" @click="sorted='';fetch()">ทั้งหมด</b-button>
                </div>
              </b-row>
            </b-col>
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive style="min-height: 300px" :fields="fields" :items="items" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(bank)="data">
                  <ul class="users-list align-items-center m-0 p-0">
                    <li
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-placement="bottom"
                      :data-original-title="(typeof(bank_list[data.value]) != undefined ? bank_list[data.value] : '-')"
                      class="avatar pull-up m-0"
                    >
                      <img
                        class="media-object rounded"
                        :src="BASE_URL + 'assets/app-assets/images/bank/' + bank_admin[data.value] + '.png'"
                        alt="Bank"
                        height="40"
                        width="40"
                      >
                    </li>
                  </ul>
                </template>
                <template #cell(sum_commission)="data">
                  {{ func.toMoney(data.value) }} ฿
                </template>
                <template #cell(sum_member)="data">
                  <router-link :to="'/agent/report_member_register/'+data.item.id" class="btn_report_member btn bg-gradient-primary waves-effect waves-light" >{{ func.toNum(data.value) }} คน : ข้อมูลรายงาน</router-link>
                </template>
                <template #cell(account)="data">
                  <b-button v-if="data.item.account_agent_username == ''" :data-id="data.item.id" :data-username="data.item.username" variant="btn-primary" class="btn bg-gradient-secondary waves-effect waves-light">
                    ยังไม่ได้รับยูสเซอร์<small class="d-block"> (กดเพื่อสร้างยูส)</small>
                  </b-button>
                  <b-button v-if="data.item.account_agent_username != ''" :data-id="data.item.id" :data-username="data.item.account_agent_username" :data-password="data.item.account_agent_password" variant="btn-primary" class="btn bg-gradient-warning waves-effect waves-light">
                    {{data.item.account_agent_username}}
                  </b-button>
                </template>
                <template #cell(manage)="data">
                  <div class="btn-group">
                    <b-dropdown variant="link" toggle-class="bg-gradient-success waves-effect waves-light">
                      <template #button-content>
                        <span><feather-icon icon="EditIcon" class="font-medium-1 mr-1" /></span>จัดการ
                      </template>
                      <b-dropdown-item :to="'/agent/login/' + data.item.id"><span><feather-icon icon="LogInIcon" class="text-warning mr-1" /></span>เอเย่นต์</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item :to="'/agent/winlost/' + data.item.id"><span><feather-icon icon="ColumnsIcon" class="text-info mr-1" /></span>แพ้ชนะ</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item :to="'/agent/commission/' + data.item.id"><span><feather-icon icon="GiftIcon" class="text-primary mr-1" /></span>คอมมิชชั่น</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item :to="'/agent/report_member/' + data.item.id"><span><feather-icon icon="BookIcon" class="text-success mr-1" /></span>สรุปฝาก-ถอน</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item :to="'/user/user_form_update/' + data.item.id"><span><feather-icon icon="EditIcon" class="text-danger mr-1" /></span>แก้ไข</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import func from '@core/comp-functions/misc/func'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    flatPickr,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      date_start_report: '',
      date_end_report: '',
      selected: { title: 'ทั้งหมด' },
      option: [
        { title: 'ทั้งหมด' },
        { title: 'ได้รับยูสเซอร์แล้ว' },
        { title: 'ยังไม่ได้รับยูสเซอร์' },
      ],
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      sorted: '',
      filter: '',
      filterOn: [],
      fields: [
        { key: 'phone', label: 'เบอร์มือถือ', class: 'text-center' },
        { key: 'full_name', label: 'ชื่อ - นามสกุล', class: 'text-center text-nowrap' },
        { key: 'bank', label: 'ธนาคาร', class: 'text-center' },
        { key: 'bank_number', label: 'เลขบัญชี', class: 'text-center' },
        { key: 'created_at', label: 'วันที่สมัคร', class: 'text-center text-nowrap' },
        { key: 'sum_commission', label: 'ยอดชำระ', class: 'text-center text-nowrap' },
        { key: 'sum_member', label: 'ยูสภายใต้ทั้งหมด', class: 'text-center text-nowrap' },
        { key: 'account', label: 'ยูสของเอเย่น', class: 'text-center' },
        { key: 'manage', label: 'จัดการข้อมูล', class: 'text-center' },
      ],
      items: [],
      t_fetch: 0,
      bank_admin: {'01': '1', '02': '2', '03': '3', '04': '5', '05': '6', '06': '4', '07': '7', '08': '9', '09': 'baac'},
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.totalRows = this.items.length
    this.fetch()
    this.t_fetch = setInterval(() => {
      if (this.currentPage != 1) return;
      this.fetch()
    }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `agent/agent_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.filter}&role=${this.sorted}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) }).catch((res) => console.log(res.status))
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
